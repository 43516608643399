<template>
  <div class="recruitment">
    <div class="container">
      <h1>诚聘英才</h1>
      <p>简历请投：zhaopin@maizuo.com，邮件标题格式：职位名称+姓名</p>
      <div class="job-temp" v-for="(item, index) in jobList" :key="index">
        <h2>{{item.jobName}}</h2>
        <h3>工作地点：</h3>
        <p>{{item.jobPlace}}</p>
        <h3>岗位职责</h3>
        <p v-html="item.jobDo"></p>
        <h3>任职资格</h3>
        <p v-html="item.qualifications"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      jobList: [
        {
          jobName:
            "1. 职位名称：市场经理 /高级市场经理职位名称：销售经理/高级销售经理",
          jobPlace:
            "深圳、重庆、成都、东莞、昆明、南宁、北京、天津、太原、沈阳、青岛、济南、上海、南京、苏州、杭州、广州、佛山、武汉、长沙、福州",
          jobDo: `1.针对电影类福利礼品方面，实施企业大客户的需求开发和销售计划，深挖客户资源转化为销售实绩；<br/>
                  2.对跟进的客户进行关系维护和销售促进，定期进行客户整理分析，制订改善策略；<br/>
                  3.负责客户的应收款回收工作，及时跟进客户的产品使用情况，协助做好售后服务工作；<br/>
                  4.协助收集竞争同业、产品市场走势等信息，提供相关市场销售建议。`,
          qualifications: `1.大专或以上学历，男女不限，年龄22-28岁；<br/>
                           2.具有1年或以上销售经验，有之前客户群体对接企业经验的优先；<br/>
                           3.形象谈吐好，性格沉稳开朗，具备良好的沟通能力及商务谈判技巧，较强的公关能力、应变能力；<br/>
                           4.热爱电影产业，具电影票务、预付卡、广告礼品及员工福利等行业工作经验者优先；<br/>
                           5.有较强的工作责任心、集体荣誉感、承压能力，高效的执行能力。`
        },
        {
          jobName: "2. 职位名称：市场经理 /高级市场经理",
          jobPlace:
            "深圳、重庆、成都、东莞、昆明、南宁、北京、天津、太原、沈阳、青岛、济南、上海、南京、苏州、杭州、广州、佛山、武汉、长沙、福州",
          jobDo: `1.合作影城关系维护及商务推进落实；<br/>
                  2.高效开拓新影城业务；<br/>
                  3.责任内合作影城的活动、销售规划及协助实施；<br/>
                  4.独立主动处理日常事务；<br/>
                  5.市场环境及竞争对手分析。`,
          qualifications: `1.大学专科或以上学历，男女不限，年龄22-35岁，市场营销、电子商务、广告等相关专业优先；<br/>
                           2.1年以上销售、市场、商务开拓等相关工作经验，对电子商务行业有一定认识；<br/>
                           3.较好的沟通与协调能力，有团队意识；<br/>
                           4.性格沉稳外向，口才好，善于处理人际关系，有良好的职业形象；<br/>
                           5.熟悉电影行业体系、有院线、电影机构、第三方电子商务平台市场工作经验者优先。<br/>
                           6.有较强的责任心跟执行能力。`
        },
        {
          jobName: "3. 职位名称：产品经理",
          jobPlace: "深圳",
          jobDo: `1.全面负责公司平台PC与手机应用商业模式拓展与平台机制运营；<br/>
                  2.负责全年的产品规划和策略；<br/>
                  3.制定具体产品执行计划并保证其得到高效高质执行，有效分析和挖掘产品数据；<br/>
                  4.探索和把握用户对电影票务及社交的增值需求，寻找新的产品增值拓展和产品商业模式；<br/>
                  5.熟悉互联网电商及SNS领域、社区增值产品策划以及平台机制运营，对影票平台有深刻理解和认识；<br/>
                  6.关注产品相关信息，拓展产品功能并提出新的产品创意。`,
          qualifications: ` 1. 本科或以上学历，3年以上产品工作经验，最好有成功产品经验；<br/>
                            2.热爱互联网行业，对互联网产品有敏锐的感觉,有创造力,善于解决问题;<br/>
                            3. 有良好的学习能力和人格魅力、能承受压力；<br/>
                            4.移动互联网相关工作经验者优先。<br/>
                            5.善于创造性的解决问题，良好的沟通能力和团队合作精神，出色的组织和沟通能力；<br/>
                            5. 有相关营收产品运营经验，具有较强的逻辑能力和系统策划能力；<br/>
                            6. 对互联网产品和流行商业模式有很强的敏锐度、领悟力强、思维活跃。`
        },
        {
          jobName: "4.职位名称：运维工程师",
          jobPlace: "北京、东莞",
          jobDo: `1.负责各影院的卖座网自助会员取票终端机、一体机、打票机等设备的安装、维护；<br/>
                  2.对卖座网所有设备进行定期维护，并负责跟进实施；<br/>
                  3.及时反馈影院各设备、设施的维护信息并跟进完善执行；<br/>
                  4.公司内部各种办公设备、网络设备的管理与维护。`,
          qualifications: ` 1.大专以上计算机、信息技术相关专业；<br/>
                            2.一年以上企业网络管理实操经验或管理经验，对网络设备如交换机、服务器、路由器等网管工作有实操经验；<br/>
                            3.精通网络维护，精通PC各种硬件、软件维护；<br/>
                            4.有独立工作能力，自学能力及吃苦耐劳的工作精神，高效的工作态度；<br/>
                            5.酷爱电脑技术，学习能力强，有独立解决问题的能力。`
        },
        {
          jobName: "5.职位名称：客服助理",
          jobPlace: "深圳",
          jobDo: `1.通过热线电话及QQ，受理客户咨询，耐心解答客户的问题；<br/>
                  2.处理客户投诉，以良好的心态帮助客户解决问题；<br/>
                  3.按需求为影城提供培训及远程升级；<br/>
                  4.定期回访，维护客户关系；<br/>
                  5.完成上级交办的其它工作。`,
          qualifications: ` 1.普通话标准，细心、耐心，有良好的服务意识；<br/>
                            2.熟练使用电脑，中文输入50字/分钟以上；<br/>
                            3.性格温和、开朗、心态积极乐观，集体观念，团队精神强；<br/>
                            4.有呼叫中心热线客服或电子商务行业从业经验者优先，欢迎优秀的应届毕业生应聘。`
        },
        {
          jobName: "6.职位名称：Java工程师",
          jobPlace: "深圳",
          jobDo: `1. 基于WEB应用的系统开发，网站应用；<br/>
                  2. 运用jsp+Java进行基于B/S结构的应用系统开发。`,
          qualifications: ` 1.本科或本科以上学历，计算机相关专业，英文水平达CET4；<br/>
                            2.具有jsp+Java开发大型项目的经验；<br/>
                            3.精通Java,JSP,Servlet,JavaBean等Java相关技术；<br/>
                            4.Webwork\spring等设计结构;熟练应用Javascript，HTML等；<br/>
                            5.精通MySQL等数据库应用；<br/>
                            6.熟练应用Apache，Tomcat等；<br/>
                            7.1年以上行业软件开发经验；<br/>
                            8.学习能力强，拥有优秀的逻辑思维能力，自我管理能力强，有良好的时间意识有较好的沟通交流能力工作认真，细心，有条理；积极性高，求知欲强；具有较强的沟通能力及团队合作精神。`
        },
        {
          jobName: "7.职位名称：前端工程师",
          jobPlace: "深圳",
          jobDo: `1.负责卖座网相关网站前端开发。`,
          qualifications: ` 1.计算机相关专业，有web前台开发工作经验；<br/>
                            2.精通JavaScript、Ajax、ExtJs、JQuery等各种Web前端开发技术；<br/>
                            3.精通HTML5、XHTML、CSS等网页制作技术，熟悉页面架构和布局；<br/>
                            4.熟悉W3C标准，对表现与数据分离、Web语义化等有较为深刻的理解；<br/>
                            5.对互联网产品和Web技术有浓厚兴趣，，热衷于开发优秀的基于 Web 的电子商务产品；<br/>
                            6.有较好的学习能力和强烈的进取心，乐观、主动、积极，抗压能力强；<br/>
                            7.对可用性、可访问性、用户体验优化、前端页面加载和渲染速度优化等相关知识有实际的了解和实践经验；<br/>
                            8.有使用服务器端脚本开发语言（Java、C/C++）开发Web应用的经验；<br/>
                            9.熟练使用各种脚本调试器、dom查看器等定位问题，熟练使用各种工具检测web服务的性能和定位瓶颈。`
        },
        {
          jobName: "8.职位名称：测试工程师",
          jobPlace: "深圳",
          jobDo: `1.负责制定项目测试计划、测试用例、执行测试并输出测试报告；<br/>
                  2.使用测试工具以及编写测试脚本执行相关自动化测试；<br/>
                  3.跟进用户反馈。`,
          qualifications: ` 1.本科及以上学历，计算机或通信相关专业，有软件测试经验者优先，英文水平达CET4；<br/>
                            2.熟悉软件开发测试流程，能独立编写测试计划、设计测试方案、测试用例和测试报告，对被测试软件具备一定测试深入分析能力；熟悉B/S架构测试，有测试管理工作经验优先；<br/>
                            3.掌握B/S测试工具，熟悉loadrunner工具的使用；<br/>
                            4.掌握Unix或Linux，能独立搭建测试环境；<br/>
                            5.较强的学习能力、沟通能力及团队合作精神，强烈的责任感及进取精神；热爱软件测试工作，可以胜任重复性工作，工作细致认真，有耐心。`
        }
      ]
    };
  }
};
</script>

<style lang="less">
.recruitment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container {
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
      font-weight: normal;
    }
    h1 {
      margin-top: 60px;
      margin-bottom: 30px;
      color: #222426;
      font-size: 36px;
    }
    h2 {
      color: #ff5f16;
      font-size: 20px;
      margin-bottom: 28px;
    }
    h3 {
      color: #222426;
      font-size: 16px;
      margin-top: 28px;
    }
    p {
      color: #787878;
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
    }
    .job-temp {
      width: 868px;
      margin-bottom: 26px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #d2d2d2;
      &:last-child {
        border-bottom: none;
        padding-bottom: 88px;
      }
    }
  }
}
</style>

